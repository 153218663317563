import React, { useEffect, useCallback, useRef, useState } from 'react';
import { PaymentsWidget, PaymentsWidgetProps } from '@wix/cashier-payments-widget/lazy';
import { usePanorama } from '@wix/yoshi-flow-editor';
import { PackagePickerInteractions } from '../../../../../types/PackagePickerFedops';
import { toError } from '../../../../../utils/errors';

export const MonitoredPaymentsWidget: React.FC<PaymentsWidgetProps> = ({ onFullLoad, onCrash, ...props }) => {
  const panorama = usePanorama();
  const isLoading = useRef(true);
  const [isAfterEffect, setAfterEffect] = useState(false);

  useEffect(() => {
    panorama.transaction(PackagePickerInteractions.CashierWidgetLoaded).start();
    setAfterEffect(true);
    return () => {
      if (isLoading.current) {
        panorama.transaction(PackagePickerInteractions.CashierWidgetLoaded).finish();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cashierWidgetLoaded = useCallback(() => {
    isLoading.current = false;
    panorama.transaction(PackagePickerInteractions.CashierWidgetLoaded).finish();
    onFullLoad?.();
  }, [panorama, onFullLoad]);

  const captureError = useCallback(
    (error: Error | string) => {
      panorama.errorMonitor().reportError(toError(error), {
        tags: {
          interaction: PackagePickerInteractions.CashierWidgetLoaded,
        },
      });
      onCrash?.(error);
    },
    [onCrash, panorama],
  );

  return isAfterEffect ? <PaymentsWidget {...props} onCrash={captureError} onFullLoad={cashierWidgetLoaded} /> : null;
};
